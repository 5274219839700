import { Paper } from '@mui/material';
import { useRequireLogin } from '../contexts/RequiresLoginContext';

export function LoadingError({ error }) {
  const requireLogin = useRequireLogin();

  // INFO: It fixes the following scenario:
    // if (isErrorAssignment || isErrorCourses || isErrorAsignees) {
    //   return <LoadingError error={errorAssignment || errorCourses || errorAssignees} />;
    // }
    // TODO: Dive deeper

  if (error?.response?.status === 401) {
    requireLogin();
    return null;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <h1>Error occurred, please try again later</h1>
      <p>{error?.message}</p>
    </Paper>
  );
}
