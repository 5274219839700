import { useContext, useRef, useState } from 'react';
import { Avatar, Box, Button, Container, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';

// Drawer imports
import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SchoolIcon from '@mui/icons-material/School';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';

// Layout Imports
import { ROUTES } from '../routes';
import { API_BASE_URL } from '../config';
import { UserContext } from '../contexts/UserContext';

const drawerTopHeight = 100;
const drawerWidth = 200;

const headerStyles = (theme) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: drawerTopHeight,

  [theme.breakpoints.up('sm')]: { flexDirection: 'row', justifyContent: 'space-between' },
});

const userButtonStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'text.primary',
  textTransform: 'none',
  fontWeight: 'normal',
  [theme.breakpoints.up('sm')]: { marginTop: 0 },
});

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useContext(UserContext);
  const ref = useRef();

  const logout = () => window.open(`${API_BASE_URL}/auth/logout`, '_self');
  const userName = user ? user.name : '';

  return (
    <Box sx={headerStyles}>
      <Link to={ROUTES.HOME} style={{ textDecoration: 'none' }}>
        <Box sx={{ display: 'flex', alignItems: 'end' }}>
          <picture>
            <source srcSet="/logo.png, /logo@2x.png 2x" />
            <img alt="WordPath" />
          </picture>
          <Typography color="text.secondary">alpha</Typography>
        </Box>
      </Link>
      {user && (
        <>
          <Button
            onClick={() => setIsMenuOpen(true)}
            endIcon={<Avatar alt={userName} src={user.avatarUrl} ref={ref} />}
            sx={userButtonStyles}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              {userName}
              {user.earlyAccess && (
                <Typography color="text.secondary" fontSize="small" ml={1}>
                  (Early Access)
                </Typography>
              )}
            </Box>
          </Button>
          <Menu
            sx={{ mt: '40px' }}
            id="menu-appbar"
            anchorEl={ref.current}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={isMenuOpen}
            onClick={() => setIsMenuOpen(false)}
            onClose={() => setIsMenuOpen(false)}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    backgroundColor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
          >
            {user.isTeacher && (
              <MenuItem component={Link} to={ROUTES.PROFILE}>
                <Typography textAlign="center">Your profile</Typography>
              </MenuItem>
            )}
            <MenuItem onClick={logout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}

function Footer() {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        height: '30vh',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '20px',
      }}
    >
      <Typography variant="body2" color="text.secondary" align="center">
        WordPath is in private alpha testing. In order to improve the app, we are recording your writing. Please do not
        enter your real name, location, phone number, email, or any other personal information.
      </Typography>
    </Box>
  );
}

// these are basic demo styles from the MUI docs
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: drawerTopHeight,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export function DrawerLayout() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const user = useContext(UserContext);
  const location = useLocation();
  const handleDrawerOpen = () => setOpen(true);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {user && (
        <>
          <AppBar position="fixed" open={open}>
            <Toolbar sx={{ backgroundColor: theme.palette.grey['100'] }}>
              <IconButton
                color="text.primary"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ marginRight: 5, ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Header />
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {user.isAdmin && user.isTeacher && (
              <ListSubheader component="div" style={{ paddingLeft: '7px' }}>
                Teacher
              </ListSubheader>
            )}
            <List>
              {!user.isTeacher && ( // TMP only for students
                <Tooltip title="Dashboard" placement="right" disableHoverListener={open}>
                  <ListItem key="Dashboard" disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                      component={Link}
                      to={user.isTeacher ? ROUTES.DASHBOARD_TEACHER : ROUTES.STUDENT_DASHBOARD}
                      selected={location.pathname.includes('/student/')}
                    >
                      <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                        <DashboardIcon fontSize="large" />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              )}
              {user.isTeacher && (
                <>
                  <Tooltip title="Assignments" placement="right" disableHoverListener={open}>
                    <ListItem key="Assignments" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                        component={Link}
                        to={ROUTES.ASSIGNMENTS_INDEX}
                        selected={location.pathname.startsWith(ROUTES.ASSIGNMENTS_INDEX)}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <AssignmentIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary="Assignments" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                  <Tooltip title="Templates" placement="right" disableHoverListener={open}>
                    <ListItem key="Templates" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                        component={Link}
                        to={ROUTES.TEMPLATES_INDEX}
                        selected={location.pathname.startsWith(ROUTES.TEMPLATES_INDEX)}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <EditNoteIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary="Templates" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                  <Tooltip title="Classes" placement="right" disableHoverListener={open}>
                    <ListItem key="Classes" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                        component={Link}
                        to={ROUTES.CLASSES_INDEX}
                        selected={location.pathname.startsWith(ROUTES.CLASSES_INDEX)}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <SchoolIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary="Classes" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </>
              )}
              {user.isAdmin && (
                <>
                  <ListSubheader component="div" style={{ paddingLeft: '12px' }}>
                    Admin
                  </ListSubheader>
                  <Tooltip title="Prompts" placement="right" disableHoverListener={open}>
                    <ListItem key="promptsConfigurations" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                        component={Link}
                        to={ROUTES.ADMIN_PROMPTS_CONFIGURATIONS}
                        selected={location.pathname === ROUTES.ADMIN_PROMPTS_CONFIGURATIONS}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <TuneIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary="Prompts" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                  <Tooltip title="Users" placement="right" disableHoverListener={open}>
                    <ListItem key="users" disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5 }}
                        component={Link}
                        to={ROUTES.ADMIN_USERS}
                        selected={location.pathname === ROUTES.ADMIN_USERS}
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                          <ManageAccountsIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                </>
              )}
            </List>
          </Drawer>
        </>
      )}

      <Box component="main" sx={{ flexGrow: 1, px: 3 }}>
        {user && <DrawerHeader />}
        <Container sx={{ display: 'flex', flexDirection: 'column', minHeight: '80vh' }} maxWidth="xl">
          {!user && <Header />}
          <Box sx={{ flex: 'auto' }}>
            <Outlet />
          </Box>
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}
