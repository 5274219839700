import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { isEmpty, omit } from 'ramda';

import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../../routes';
import { GRADES } from '../../../helpers/courses';
import { useCourseMutation } from '../api/useCourseMutation';
import { GoogleCourseField } from './google/GoogleCourseField';
import { StudentsListField } from './StudentsListField';
import { DeleteCourseButton } from './DeleteCourseButton';
import { SynchronizeStudents } from './google/SynchronizeStudents';

const ERROR_MESSAGES = {
  name: 'You need to pick a name for your class',
  grade: 'You must select the class grade',
};

const EMPTY_ERRORS = {
  name: '',
  grade: '',
};

const getFormData = omit(['students-name']);

export function ClassForm({ course, googleCourses }) {
  const navigate = useNavigate();
  const [selectedClassroom, setSelectedClassroom] = useState('');
  const [formValues, setFormValues] = useState(course || {});
  const [formErrors, setFormErrors] = useState(EMPTY_ERRORS);
  const mutation = useCourseMutation({
    // INFO: inside we invalidate CLASSES_QUERY_KEY but it does not work when creating a new class
    onSuccess: (result) => {
      navigate(ROUTES.showClass(result.course.id));
      if (course) {
        enqueueSnackbar('Changes saved!', { variant: 'success' });
      }
    },
  });

  const validateForm = (values) => {
    const errors = { ...EMPTY_ERRORS };

    if (isEmpty(values.courseId)) {
      errors.courseId = ERROR_MESSAGES.courseId;
    }

    if (isEmpty(values.grade)) {
      errors.grade = ERROR_MESSAGES.grade;
    }

    setFormErrors(errors);

    return !Object.values(errors).some(Boolean);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const studentIds = formData.getAll('students-id');
    const students = formData
      .getAll('students-name')
      .filter(Boolean)
      .map((name, idx) => ({
        name,
        id: studentIds[idx] ? parseInt(studentIds[idx], 10) : undefined,
      }));
    const values = {
      ...getFormData(Object.fromEntries(formData)),
      students,
    };

    if (validateForm(values)) {
      mutation.mutate({ data: values });
    }
  };

  const handleFieldChange = (e) => {
    setFormErrors((currentErrors) => {
      if (!isEmpty(currentErrors[e.target.name])) {
        return {
          ...currentErrors,
          [e.target.name]: '',
        };
      }

      return currentErrors;
    });
  };

  const handleGoogleClassroomChange = (values) => {
    setSelectedClassroom(values.googleCourseId);
    setFormValues(values);
  };

  return (
    <Box
      key={selectedClassroom}
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {course && <input type="hidden" name="id" value={course.id} />}
      {(course?.googleCourseId || googleCourses) && (
        <>
          <GoogleCourseField
            course={course}
            googleCourseId={formValues.googleCourseId}
            googleCourses={googleCourses}
            onChange={handleGoogleClassroomChange}
          />
          <Divider>
            <Typography
              color="text.secondary"
              sx={{
                fontSize: '0.75rem',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}
            >
              {formValues.googleCourseId ? 'and fill in other details' : 'or create class manually'}
            </Typography>
          </Divider>
        </>
      )}
      <TextField
        defaultValue={formValues.name || ''}
        error={!isEmpty(formErrors.name)}
        fullWidth
        id="course-name"
        label="Class name"
        name="name"
        onChange={handleFieldChange}
        variant="filled"
      />
      <TextField
        defaultValue={formValues.description || ''}
        fullWidth
        id="course-description"
        label="Class description"
        name="description"
        onChange={handleFieldChange}
        variant="filled"
      />
      <TextField
        defaultValue={formValues.grade || ''}
        error={!isEmpty(formErrors.grade)}
        fullWidth
        helperText={formErrors.grade}
        id="course-grade"
        label="Grade"
        name="grade"
        onChange={handleFieldChange}
        select
        variant="filled"
      >
        {GRADES.map((grade) => (
          <MenuItem key={grade} value={grade}>
            {grade}
          </MenuItem>
        ))}
      </TextField>
      <StudentsListField
        defaultValue={formValues.students || []}
        googleClass={Boolean(formValues.googleCourseId)}
        course={course}
        id="course-students"
        label="List of students"
        name="students"
      />
      <Box sx={{ display: 'flex', justifyContent: course ? 'space-between' : 'flex-end' }}>
        {course && <DeleteCourseButton courseId={course.id} />}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingY: 2,
            gap: 2,
          }}
        >
          <Button
            component={Link}
            to={course ? ROUTES.showClass(course.id) : ROUTES.CLASSES_INDEX}
            size="small"
            variant="outlined"
          >
            Cancel
          </Button>
          {course?.googleCourseId && (
            <SynchronizeStudents courseId={course.id}>
              Synchronize students list with Google Classroom
            </SynchronizeStudents>
          )}
          <Button disabled={mutation.isLoading} type="submit" variant="contained" size="small">
            {mutation.isLoading && <CircularProgress size={15} sx={{ margin: 0.5 }} />}
            {!mutation.isLoading && (course ? 'Update class' : 'Create class')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
