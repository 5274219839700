import { generatePath } from 'react-router-dom';

/* eslint-disable max-len */
export const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',

  // TODO: rename assignmentId to id
  ASSIGNMENTS_INDEX: '/assignments',
  ASSIGNMENTS_NEW: '/assignments/new', newAssignment: () => ROUTES.ASSIGNMENTS_NEW,
  ASSIGNMENTS_EDIT: '/assignments/:assignmentId/edit', editAssignment: (assignmentId) => generatePath(ROUTES.ASSIGNMENTS_EDIT, { assignmentId }),
  ASSIGNMENTS_SHOW: '/assignments/:assignmentId', showAssignment: (assignmentId) => generatePath(ROUTES.ASSIGNMENTS_SHOW, { assignmentId }),
  ASSIGNMENTS_WRITE: '/assignments/:assignmentId/:userId/write', writeAssignment: (assignmentId, userId) => generatePath(ROUTES.ASSIGNMENTS_WRITE, { assignmentId, userId }),
  ASSIGNMENTS_ASSIGN: '/assignments/:assignmentId/assign', assignToAssignment: (assignmentId) => generatePath(ROUTES.ASSIGNMENTS_ASSIGN, { assignmentId }),
  ASSIGNMENTS_TEMPLATE: '/assignments/:assignmentId/template', editAssignmentTemplate: (assignmentId) => generatePath(ROUTES.ASSIGNMENTS_TEMPLATE, { assignmentId }),
  ASSIGNMENTS_SUBMISSION: '/assignments/:assignmentId/submissions/:studentId', showAssignmentSubmission: (assignmentId, studentId) => generatePath(ROUTES.ASSIGNMENTS_SUBMISSION, { assignmentId, studentId }),
  ASSIGNMENTS_ANALYTICS: '/assignments/:assignmentId/analytics/:studentId', showAssignmentAnalytics: (assignmentId, studentId) => generatePath(ROUTES.ASSIGNMENTS_ANALYTICS, { assignmentId, studentId }),

  TEMPLATES_INDEX: '/templates',
  TEMPLATES_NEW: '/templates/new',
  TEMPLATES_EDIT: '/templates/:templateId/edit', editTemplate: (templateId) => generatePath(ROUTES.TEMPLATES_EDIT, { templateId }),

  CLASSES_INDEX: '/classes',
  CLASSES_NEW: '/classes/new', newClass: () => ROUTES.CLASSES_NEW,
  CLASSES_SHOW: '/classes/:courseId', showClass: (courseId) => generatePath(ROUTES.CLASSES_SHOW, { courseId }),
  CLASSES_EDIT: '/classes/:courseId/edit', editClass: (courseId) => generatePath(ROUTES.CLASSES_EDIT, { courseId }),
  CLASSES_STUDENT: '/classes/:courseId/students/:studentId', showStudent: (courseId, studentId) => generatePath(ROUTES.CLASSES_STUDENT, { courseId, studentId }),

  ADMIN_USERS: '/admin/users',
  ADMIN_USERS_NEW: '/admin/users/new',
  ADMIN_USERS_EDIT: '/admin/users/:userId/edit', editUser: (userId) => generatePath(ROUTES.ADMIN_USERS_EDIT, { userId }),
  ADMIN_PROMPTS_CONFIGURATIONS: '/admin/prompts-configurations',
  ADMIN_DEBUG: '/admin/debug',

  STUDENT_DASHBOARD: '/student',
  STUDENT_CLASS: '/student/:courseId', showStudentClass: (courseId) => generatePath(ROUTES.STUDENT_CLASS, { courseId }),
  STUDENT_WRITE: '/student/assignments/:assignmentId/write', studentWrite: (assignmentId) => generatePath(ROUTES.STUDENT_WRITE, { assignmentId }),
  STUDENT_SUBMISSION: '/student/assignments/:assignmentId/:studentId', showStudentSubmission: (assignmentId, studentId) => generatePath(ROUTES.STUDENT_SUBMISSION, { assignmentId, studentId }),
  // TODO
  // DASHBOARD_TEACHER: '/teacher/dashboard',
};
