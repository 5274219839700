import { Container, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import { LoadingError } from '../../../../components/LoadingError';
import { useRequireLogin } from '../../../../contexts/RequiresLoginContext';
import { useCourse } from '../../api/useCourse';
import { ClassForm } from '../../components/ClassForm';
import { Breadcrumb } from '../../../../components/layout/Breadcrumb';

export function ClassEditPage() {
  const { courseId } = useParams();
  const { data: course, isLoading, isError, error } = useCourse(courseId);
  const requireLogin = useRequireLogin();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError && error.response?.data?.googleSync) {
    requireLogin();
    return null;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  return (
    <Container>
      <Breadcrumb courseId={courseId} courseName={course.name} />
      <Paper sx={{ padding: 2 }}>
        <ClassForm course={course} />
      </Paper>
    </Container>
  );
}
