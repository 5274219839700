import { Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { ROUTES } from '../../routes';
import { UserContext } from '../../contexts/UserContext';
import { LOGIN_STUDENT_URL, LOGIN_TEACHER_URL, LOGIN_URL } from '../../config';
import { GoogleLoginButton } from './GoogleLoginButton';
import { CodeLogin } from './CodeLogin';

const login = () => window.open(LOGIN_URL, '_self');
const loginAsTeacher = () => window.open(LOGIN_TEACHER_URL, '_self');
const loginAsStudent = () => window.open(LOGIN_STUDENT_URL, '_self');

const useMessages = () => {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    switch (message) {
      case 'code-login-failed':
        enqueueSnackbar('Access code login failed. Please try again.', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        navigate('/', { replace: true });
        break;
      default:
      // Do nothing
    }
  }, [message]);
};

export function HomePage() {
  const user = useContext(UserContext); // todo czy to nie powinien byc po prostu useUserContext ?
  useMessages();

    const commonPaperStyle = {
      padding: '20px',
      textAlign: 'center',
      height: '100%', // Ustawia wysokość na 100%
    };

    if (user) {
      if (user.isTeacher) {
        return <Navigate replace to={ROUTES.ASSIGNMENTS_INDEX} />;
      }

      if (user.isAdmin) {
        return <Navigate replace to={ROUTES.ADMIN_USERS} />;
      }

      return <Navigate replace to={ROUTES.STUDENT_DASHBOARD} />;
    }

    return (
      <Container>
        <Typography variant="h2" component="h1" color="text.main" textAlign="center" sx={{ margin: 4 }}>
          Login
        </Typography>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={6}>
            <Paper elevation={3} style={{ ...commonPaperStyle }}>
              <Typography variant="h3" gutterBottom marginBottom="40px">
                Teacher
              </Typography>
              <GoogleLoginButton onClick={loginAsTeacher} />
              <Divider light style={{ margin: '30px 0' }} />
              <Typography component="h2" sx={{ marginBottom: 2 }} variant="h5">
                Email and password
              </Typography>
              <Button onClick={login} variant="contained">
                Log in
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper elevation={3} style={{ ...commonPaperStyle }}>
              <Typography variant="h3" gutterBottom marginBottom="40px">
                Student
              </Typography>
              <GoogleLoginButton onClick={loginAsStudent} />
              <Divider light style={{ margin: '30px 0' }} />
              <Typography component="h4" sx={{ marginBottom: 2 }} variant="h5">
                Access Code
              </Typography>
              <CodeLogin />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
}
