import { useContext, useState } from 'react';
import { Backdrop, Box, Container, Paper, Tab } from '@mui/material';
import { Link, Navigate, useParams } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import { UserContext } from '../../../contexts/UserContext';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { LoadingError } from '../../../components/LoadingError';
import { useRequireLogin } from '../../../contexts/RequiresLoginContext';
import { Breadcrumb } from '../../../components/layout/Breadcrumb';
import { useCoursesList } from '../api/useCoursesList';
import { ROUTES } from '../../../routes';
import { SpeedDialAddButton } from '../../../components/SpeedDialAddButton';
import { CenteredTabs } from '../../../components/layout/CenteredTabs';
import { StudentsList } from './StudentsList';
import { AssignmentsAssignedToStudents } from './AssignmentsAssignedToStudents';

export function ClassPage() {
  const { courseId = false } = useParams();
  const { data: courses, isLoading, isError, error } = useCoursesList(); // TODO: double check if we can remove assignments relation
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const currentUser = useContext(UserContext);

  // TODO: Consider getting all data for both sections here so we can reduce api payload
  // const {
  //   data: assignments,
  //   isLoading: ,
  //   isError,
  //   error,
  // } = studentIds.length > 0
  //   ? useStudentsAssignments(studentIds)
  //   : { data: [], isLoading: false, isError: false, error: null };

  // TODO: Not sure if that's the best possible solution
  const requireLogin = useRequireLogin();
  if (isError && error.response?.data?.googleSync) {
    requireLogin();
    return null;
  }
 
  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return <LoadingError error={error} />;
  }

  if (!courseId) {
    return <Navigate replace to={ROUTES.showClass(courses[0].id)} />;
  }

  const currentCourse = courses.find((item) => item.id.toString() === courseId);
  // TODO: It's tmp but we have to fix a problem when you create a new class and go back to this view
  if (!currentCourse) {
    return <Navigate to={ROUTES.CLASSES_INDEX} />;
  }

  const coursesEditActions = courses.map((course) => ({
    icon: <SchoolIcon />,
    name: `Edit '${course.name}'`,
    route: ROUTES.editClass(course.id),
  }));

  const speedDialActions = [
    { icon: <SchoolIcon />, name: 'New Class', route: ROUTES.CLASSES_NEW },
    ...coursesEditActions,
  ];

  const userAssignments = currentCourse.courseUsers.filter((user) => user.userId !== currentUser.id);
  const studentIds = userAssignments.map((userAssignment) => userAssignment.userId);

  return (
    <Container>
      <Breadcrumb courseId={currentCourse.id} courseName={currentCourse.name} />
      <SpeedDialAddButton actions={speedDialActions} onOpen={handleOpen} onClose={handleClose} />
      <Paper sx={{ padding: 2 }} variant="outlined">
        <>
          <CenteredTabs value={courseId}>
            {courses.map((course) => (
              <Tab
                key={course.id}
                component={Link}
                label={course.name}
                value={course.id.toString()}
                to={ROUTES.showClass(course.id)}
              />
            ))}
          </CenteredTabs>
          <Box>
            <AssignmentsAssignedToStudents studentIds={studentIds} />
          </Box>
          <Box sx={{ marginY: 3 }}>
            <StudentsList course={currentCourse} courseOwner={currentUser} />
          </Box>
          <Backdrop open={open} onClick={handleClose} />
        </>
      </Paper>
    </Container>
  );
}
